// External Libraries
import * as React from "react";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { observer } from "mobx-react";
import { useLocalization } from "@shared-ui/localization-context";
import {
  EgClickstreamImpressionTracker,
  EgClickstreamImpressionTrackerProps,
} from "@shared-ui/clickstream-analytics-context";

// Components
import { HotelsContext } from "components/flexComponents/Hotels/components/HotelsContext";
import { SummarizedHotelCard } from "components/flexComponents/Hotels/components/SummarizedHotelCard/SummarizedHotelCard";
import IsomorphicCarousel from "components/shared/IsomorphicCarousel/IsomorphicCarousel";
import { SummarizedHotelsCommonView } from "components/flexComponents/Hotels/components/SummarizedHotelCard/SummarizedHotelsCommonView";
import { useMapPropertySearchById } from "src/components/flexComponents/Hotels/hooks/useMapPropertySearchById";
import { withStores } from "src/stores";
import { hotelStructuredData } from "components/flexComponents/Hotels/utils/getHotelStructuredData";
import { SchemaOrgScript } from "components/utility/SchemaOrg/SchemaOrgScript";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";

// Types
import { HotelsProps, SummarizedHotelsQueryComponentProps } from "components/flexComponents/Hotels/typings";
import { HotelsFlexModuleResult } from "typings/microserviceModels/hotels-flex-module";

// Uitk
import { UitkSpacing } from "@egds/react-core/spacing";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { egRecsClickstreamPresentedEvent } from "components/flexComponents/Hotels/utils/egRecsEvents";

import { EG_RECS_STRATEGY } from "components/flexComponents/Hotels/utils/constants";

const SummarizedHotelsCarousel: React.FC<HotelsProps> = (props: HotelsProps) => {
  const { templateComponent, context, hotels, flexModuleModelStore } = props;

  const {
    metadata: { id },
  } = templateComponent;
  const model = flexModuleModelStore.getModel(id) as HotelsFlexModuleResult | null;

  if (!model || model.hotels?.length === 0) {
    return null;
  }

  return (
    <SummarizedHotelsCarouselQueryComponent
      templateComponent={templateComponent}
      context={context}
      model={model}
      hotels={hotels}
    />
  );
};

const SummarizedHotelsCarouselQueryComponent = (props: SummarizedHotelsQueryComponentProps) => {
  const { templateComponent, context, model, hotels: hotelsStore } = props;

  const isEgRecsStrategy = Boolean(EG_RECS_STRATEGY.includes(model.strategy));

  const {
    metadata: { id },
    config: { fmTitleId },
  } = templateComponent;
  const fmId = getFmId(templateComponent);
  const { hideTitle } = templateComponent.config;

  const { formatText } = useLocalization();
  useMapPropertySearchById(model, hotelsStore, context);

  const HotelsWrapper = isEgRecsStrategy ? EgClickstreamImpressionTracker : React.Fragment;
  const hotelsWrapperProps = isEgRecsStrategy
    ? { eventData: egRecsClickstreamPresentedEvent(model), trackOnce: true }
    : ({} as EgClickstreamImpressionTrackerProps);

  return (
    <LazyLoad context={context}>
      <HotelsContext.Provider value={{ context, templateComponent, model }}>
        <SchemaOrgScript structuredData={hotelStructuredData(model.hotels)} />
        <UitkSpacing padding={{ block: "three" }}>
          <div className="SummarizedHotelsCarousel Hotels" id={id} data-fm={fmId} data-fm-title-id={fmTitleId}>
            <HotelsWrapper {...hotelsWrapperProps}>
              <SummarizedHotelsCommonView hideTitle={hideTitle}>
                <UitkSpacing padding={{ blockstart: "three" }}>
                  <IsomorphicCarousel
                    itemsVisible={{ sm: 1, md: 2, lg: 2 }}
                    pageBy={1}
                    peek
                    buttonText={{
                      nextButton: formatText("carousel.item.next"),
                      prevButton: formatText("carousel.item.prev"),
                    }}
                    itemsMaxHeight
                    wrapItems
                  >
                    {model.hotels.map((hotel, index) => (
                      <Viewport key={`summarized-hotels-carousel-${hotel.hotelId}`}>
                        <ViewSmall>
                          <SummarizedHotelCard key={hotel.hotelId} hotel={hotel} index={index} isMobile />
                        </ViewSmall>
                        <ViewMedium>
                          <SummarizedHotelCard key={hotel.hotelId} hotel={hotel} index={index} isMobile={false} />
                        </ViewMedium>
                      </Viewport>
                    ))}
                  </IsomorphicCarousel>
                </UitkSpacing>
              </SummarizedHotelsCommonView>
            </HotelsWrapper>
          </div>
        </UitkSpacing>
      </HotelsContext.Provider>
    </LazyLoad>
  );
};

export const SummarizedHotelsCarouselView = withStores(
  "hotels",
  "context",
  "flexModuleModelStore"
)(observer(SummarizedHotelsCarousel));

export default SummarizedHotelsCarouselView;
